
  import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
  import { setCurrentPageBreadcrumbs } from '@src/core/helpers/breadcrumb'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  import { useStore } from 'vuex'
  import settingsStore from '@src/store/enums/settings'

  export default defineComponent({
    name: 'Dashboard',
    setup () {
      const store = useStore()
      onBeforeMount(() => {
        store.dispatch(settingsStore.Actions.SET_ACTIVE_SUBMENU, 'Global')
      })

      onMounted(() => {
        setCurrentPageBreadcrumbs(
          trans('nav.aside.dashboard.home'),
          [
            capitalize(transChoice('global.dashboard', 1))
          ]
        )
      })

      // Mockup data -- REMOVE WHEN DONE!
      const list = ref([
        {
          color: 'success',
          title: 'Nytt produkt trenger render',
          text: 'Kunde: SG, Templat: Stue'
        },
        {
          color: 'primary',
          title: 'Produkt er lagt til i templat Stue',
          text: 'Kunde: Byggma'
        },
        {
          color: 'warning',
          title: 'Nytt produkt i templat Soverom',
          text: 'Kunde: SG'
        },
        {
          color: 'primary',
          title: 'Nytt produkt er lagt til',
          text: 'Kunde: Block Watne'
        },
        {
          color: 'danger',
          title: 'Nytt produkt trenger render',
          text: 'Kunde: Block Watne'
        },
        {
          color: 'success',
          title: 'Produkt er lagt til i templat Stue',
          text: 'Kunde: SG'
        }
      ])

      const checked = ref(false)

      const listTemplates = [
        {
          name: 'Stue',
          skills: 'Generelt stuetemplat',
          value: '1532',
          color: 'primary',
          percent: 100
        },
        {
          name: 'Kjøkken/Stue',
          skills: 'Kombinert kjøkken og stue templat',
          value: '1753',
          color: 'danger',
          percent: 45
        },
        {
          name: 'Bad',
          skills: 'Et standard bad',
          value: '7842',
          color: 'success',
          percent: 90
        },
        {
          name: 'Kjøkken',
          skills: 'Kjøkkentemplat sett rett forfra med spiseplass',
          value: '16935',
          color: 'warning',
          percent: 97
        },
        {
          name: 'Soverom stort',
          skills: 'Templat for et over middels stort soverom',
          value: '22868',
          color: 'info',
          percent: 10
        }
      ]

      return {
        list,
        listTemplates,
        checked
      }
    }
  })
